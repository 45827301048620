define(['$window', 'app'], ($window, app) => {

  const customerReturn = () => {

    const component = {};

    const _config = {
      selectors: {
        returnReasonOptions: '[data-js-element=returnReasonCheck_options]',
        actionValue: '#actionValue',
        jsError: '[class*=js-error]',
        productList: '#product-list',
        productReturnList: '#product-return-list',
        productQueryInstruction: '.customerService_product_query_instruction',
        contactUsSection: '#contact-us-return',
        returnButtonContainer: '.customerQuery_return_button_container'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.returnReasonOptions = component.element.querySelector(component.config.selectors.returnReasonOptions);
      component.actionValue = component.element.querySelector(component.config.selectors.actionValue);
      component.jsErrors = component.element.querySelectorAll(component.config.selectors.jsError);
      component.productList = component.element.querySelector(component.config.selectors.productList);
      component.productReturnList = component.element.querySelector(component.config.selectors.productReturnList);
      component.productQueryInstruction = component.element.querySelector(component.config.selectors.productQueryInstruction);
      component.contactUsSection = component.element.querySelector(component.config.selectors.contactUsSection);
      component.returnButtonContainer = component.element.querySelector(component.config.selectors.returnButtonContainer);

      component.bind();
      return component;
    };

    const _bind = () => {
      component.returnReasonOptions.addEventListener('change', component.returnReasonOptionsChanged);
    };


    const _returnReasonOptionsChanged = () => {
      var returnReasonId = component.returnReasonOptions.value;
      if (parseInt(returnReasonId, 10) === 2) {
        if(component.actionValue.value === 'return') {
          if (component.jsErrors) {
            component.jsErrors.forEach(jsError => jsError.classList.add('hidden'));
          }
          component.productList.classList.add('hidden');
          component.productReturnList.classList.remove('hidden');
          component.productQueryInstruction.classList.add('hidden');
          component.contactUsSection.classList.add('hidden');
          component.returnButtonContainer.classList.remove('hidden');
        }
      } else {
        if (component.jsErrors) {
          component.jsErrors.forEach(jsError => jsError.classList.remove('hidden'));
        }
        component.productList.classList.remove('hidden');
        component.productReturnList.classList.add('hidden');
        component.productQueryInstruction.classList.remove('hidden');
        component.contactUsSection.classList.remove('hidden');
        component.returnButtonContainer.classList.add('hidden');
      }
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.returnReasonOptionsChanged = _returnReasonOptionsChanged;

    return component;
  };

  return customerReturn;
});
